
import { createSelector } from 'reselect'
import { IOrderWindow } from '../actions/order';
import { DISCOUNT_CODE_STATES, DISCOUNT_TYPE } from '../admin/discount/constants';
import { IdiscountInitialState } from '../reducers/discount';
import { RootState } from '../reducers/rootReducer';
import { initialState as OrderInitialState, MALL_CLEANING_SET } from '../reducers/order';

interface IDiscountParams {
    price: number,
    isFinalPrice: boolean
}

export const VERKKOTILAAJAN_ETU: number = 30
export const MAKSUTAPA_ALENNUS: number = 7.9
export const REPEATED_ORDER_DISCOUNT: number = 0

export const isMallWindow = (window: any): boolean => {
    const name = window.title ? window.title : window.name;
    return name.toLowerCase().startsWith('näyteikkuna');
}
const getInitialMonth = () => {
    const today = new Date(), month = today.getMonth()
    const cMonth = month === 11 ? 0 : month + 1;
    return cMonth // need only month for now.
    // Returns the Date of last day of the cMonth.
    // return new Date(cYear, cMonth + 1, 0) 
}

export const basePriceSelector = (state:RootState) => state.counter.config.base_price;
export const minimumPriceSelector = (state:RootState) => state.counter.config.minimum_price;
export const discountSelector = (state:RootState) => state.discount;
export const discountTypeSelector = (state:RootState) => state.discount.discount.type;

export const getOrderInitPayload = createSelector(
    (state:RootState) => state,
    (state:RootState) => {
        const {config } = state.counter;
        if(config.windows) {
            const isMallConfig = config.windows.find((w: any) => isMallWindow(w));
            const mallAddress = { // Set Itis as default
                ...OrderInitialState.order.address,
                postalCode: '00930',
                company: true, // this is necessary even though others are not to set here.
                streetAddress: 'ITIS'
            }
            const initWindows = config.windows.map((window: any) => ({
                windowId: window.id, 
                name: window.title, 
                amount: window.default_amount, 
                price: window.price, 
                discount_price: 0,
                easoft_item_id: window.easoft_item_id
            }))
            return {
                windows: initWindows,
                balconyGlassAmount: config.balcony_default_amount,
                balconyRailings: false, 
                balconyRailingMeters: 0,
                balconyRailingsObstacle: undefined,
                terraceGlassAmount: config.terrace_default_amount,
                terraceRoof: false,
                miscSealAmount: 0, // config.misc_seal_default_amount?
                miscFastenerAmount: 0, // config.misc_fastener_default_amount?
                shippingDay: undefined,
                cleanerId: false,
                address: isMallConfig ? mallAddress : OrderInitialState.order.address,
                additionalInfo: '',
                highGlassAmount: 0,
                highOpeningAmount: config.high_window_default_amount,
                mall: isMallConfig ? { mall_name: undefined, cleaning_set: MALL_CLEANING_SET.BASIC, starting_month: getInitialMonth() } : undefined
            }
        }
        return false;
    }
)

export const getOrderDisabled = createSelector(
    (state:RootState) => state,
    (state:RootState) => {

        const { order } = state.order
        const windowAmount = order.windows.reduce((acc:number, window: IOrderWindow) => acc + window.amount,0)
        return {
            orderDisabled: windowAmount + 
                order.balconyGlassAmount + 
                order.terraceGlassAmount + 
                order.balconyRailingMeters +
                order.miscSealAmount +
                order.miscFastenerAmount +
                order.highGlassAmount +
                order.highOpeningAmount > 0 ? false : true 
        }
    }
)


export const getCombinedOrderWindows = createSelector(
    (state:RootState) => state,
    (state:RootState) => ((state.counter.config.windows && state.order.order.windows.length > 0)  ? 
        state.order.order.windows.map(( window:IOrderWindow) => {
            const windowConfig = state.counter.config.windows.find((windowConfig:any) => windowConfig.id === window.windowId)
            return {
                windowId: windowConfig.id,
                amount: window.amount, 
                name: windowConfig.title,
                name_eng: windowConfig.title_eng
            };
        },[]): []
    )
);

const calculateDiscount = createSelector(
    discountSelector,
    (_:any, params:IDiscountParams) => params,
    (discountState:IdiscountInitialState, params: IDiscountParams) => {
        
        if(discountState.discount.discount !== undefined && discountState.valid === DISCOUNT_CODE_STATES.VALID){
            const {price, isFinalPrice} = params;
            // console.log('calculateDiscount', price, isFinalPrice, discountState.discount);
            
            // Calculate PERCENT type discounts
            if(discountState.discount.type === DISCOUNT_TYPE.PERCENT) { 
                return Math.floor(price - (price * discountState.discount.discount / 100))
            }

            // Calculate FIXED type discount only to final price
            if(discountState.discount.type === DISCOUNT_TYPE.FIXED && isFinalPrice) {
                return price - discountState.discount.discount;
            } 

        }
        
        return NaN
})

export const getWindowsTotal = createSelector(
    discountSelector,
    (state:RootState) => state,
    (discountState:IdiscountInitialState, state:RootState) => {
        // Init "Windows total" and "Windows total after discount"
        let defaultTotals = {wTotal: 0, wdTotal: 0};        
        let winTotal = ((state.counter.config.windows && state.order.order.windows.length > 0) ?
            state.order.order.windows.reduce((acc: any, window:IOrderWindow) => {
                if(isNaN(window.amount))
                    return {
                        wTotal: acc.wTotal + 0, 
                        wdTotal: acc.wdTotal + 0
                    };

                // Calculate PERCENT discounts
                const discountPrice = discountState.discount.type === DISCOUNT_TYPE.PERCENT ? 
                    acc.wdTotal + window.amount * calculateDiscount(state, {price: window.price, isFinalPrice: false}) : 0
               
                return {
                    wTotal: acc.wTotal + window.amount * window.price, 
                    wdTotal: discountPrice
                };
            },defaultTotals) : defaultTotals)

            // Add High window price
            const {highGlassAmount, highOpeningAmount} = state.order.order;
            const {high_window_base_price, high_window_opening_base_price, high_window_price, high_window_opening_price} = state.counter.config 

            const highTotal = highGlassAmount > 0 ? (high_window_base_price + (highGlassAmount * high_window_price) ) : 0;
            const highOpeningTotal = highOpeningAmount > 0 ? high_window_opening_base_price + (highOpeningAmount * high_window_opening_price) : 0;

            let highTotalDiscount:number = 0;
            let highOpeningTotalDiscount:number = 0;

            if(discountState.discount.type === DISCOUNT_TYPE.PERCENT){
                highTotalDiscount = highGlassAmount * calculateDiscount(state, {price: high_window_price, isFinalPrice: false});
                highOpeningTotalDiscount = highOpeningAmount * calculateDiscount(state, {price: high_window_opening_price, isFinalPrice: false});
            }

            winTotal.wTotal = winTotal.wTotal + highTotal + highOpeningTotal
            winTotal.wdTotal = winTotal.wdTotal + highTotalDiscount + highOpeningTotalDiscount
            

        return {
            windowsTotal: winTotal.wTotal, 
            windowDiscountTotal: winTotal.wdTotal || NaN
        };
}
);


export const getBalconyTotal = createSelector(
    (state:RootState) => state,
    (state:RootState) => {
        const {config } = state.counter
        const {balconyGlassAmount, balconyRailings, balconyRailingsObstacle, balconyRailingMeters} = state.order.order
        if(config && balconyGlassAmount > 0) {
            let balconyTotal = balconyGlassAmount * config.balcony_glass_price
            let balconyDiscountTotal = balconyGlassAmount * calculateDiscount(state, {price: config.balcony_glass_price, isFinalPrice: false})
            let balconyRailingPrice:number = 0;

            if(balconyRailings) {
               balconyRailingPrice = config.balcony_glass_railing_price
            }

            if(balconyRailingsObstacle) {
                balconyRailingPrice = balconyRailingPrice + config.balcony_glass_railing_obstacle_price
            }
            const balconyRailingTotal =  balconyGlassAmount * balconyRailingPrice
            const balconyRailingDiscountTotal =  balconyGlassAmount * calculateDiscount(state, {price: balconyRailingPrice, isFinalPrice: false})

            return {
                balconyTotal: balconyTotal + balconyRailingTotal, 
                balconyDiscountTotal: balconyDiscountTotal + balconyRailingDiscountTotal
            }
        // Only railing ordered
        }else if(config && balconyRailingMeters > 0){
            let balconyTotal = balconyRailingMeters * config.balcony_glass_railing_meter_price;
            let balconyDiscountTotal = balconyRailingMeters * calculateDiscount(state, {price: config.balcony_glass_railing_meter_price, isFinalPrice: false})


            if(balconyRailingsObstacle){
                balconyTotal += balconyRailingMeters * config.balcony_glass_railing_obstacle_meter_price
                balconyDiscountTotal += balconyRailingMeters * calculateDiscount(state, {price: config.balcony_glass_railing_obstacle_meter_price, isFinalPrice: false})
            }
            
            return {
                balconyTotal: balconyTotal, 
                balconyDiscountTotal: balconyDiscountTotal
            }
            
        }
        return {
            balconyTotal: 0, 
            balconyDiscountTotal: NaN
        }
    });

export const getTerraceTotal = createSelector(
    (state:RootState) => state,
    (state:RootState) => {
        const {config} = state.counter
        const {order} = state.order
        let terraceRoof:number = 0;
        let terraceRoofDisc:number = NaN;
        
        if(config && order.terraceGlassAmount > 0) {
            let terraceTotal = order.terraceGlassAmount * config.terrace_glass_price
            let terraceTotalDiscount = order.terraceGlassAmount * calculateDiscount(state, {price: config.terrace_glass_price, isFinalPrice: false})
            

            //TODO: Terrace rooftop have now fixed price all the time
            if(order.terraceRoof /*&& order.terraceGlassAmount > 0*/){

                terraceRoof = config.terrace_glass_roof_fixed_price;
                terraceRoofDisc = calculateDiscount(state, {price: config.terrace_glass_roof_fixed_price, isFinalPrice: false})
                /*iterraceRoof = order.terraceGlassAmount * config.terrace_glass_roof_price
                terraceRoofDisc = order.terraceGlassAmount * calculateDiscount(state, {price: config.terrace_glass_roof_price, isFinalPrice: false})
                */
            }

            return {
                terraceTotal: terraceTotal + terraceRoof,
                terraceDiscountTotal: terraceTotalDiscount + terraceRoofDisc
            }
        } 

        //TODO: Terrace rooftop have now fixed price all the time
        if(order.terraceRoof && order.terraceGlassAmount === 0){
            terraceRoof = config.terrace_glass_roof_fixed_price;
            terraceRoofDisc = calculateDiscount(state, {price: config.terrace_glass_roof_fixed_price, isFinalPrice: false})
        }

        return {
            terraceTotal: terraceRoof,
            terraceDiscountTotal: terraceRoofDisc
        }
    });

export const getMiscTotal = createSelector(
    (state: RootState) => state,
    (state: RootState) => {
        const { config } = state.counter
        const { miscSealAmount, miscFastenerAmount } = state.order.order

        let sealsTotal = 0, sealsTotalDiscount: number = NaN, fastenerTotal = 0, fastenerTotalDiscount: number = NaN;

        if (config && miscSealAmount) {
            const openingPrice = config.misc_seal_base_price;
            sealsTotal = openingPrice + (miscSealAmount * config.misc_seal_price)
            sealsTotalDiscount = openingPrice + (miscSealAmount * calculateDiscount(state, { price: config.misc_seal_price, isFinalPrice: false }))
        }
        if (config && miscFastenerAmount) {
            fastenerTotal = miscFastenerAmount * config.misc_fastener_price
            fastenerTotalDiscount = miscFastenerAmount * calculateDiscount(state, { price: config.misc_fastener_price, isFinalPrice: false })
        }


        return {
            miscTotal: sealsTotal + fastenerTotal,
            miscDiscountTotal: sealsTotalDiscount + fastenerTotalDiscount
        }
    });

export const getWashingTime = createSelector(
    (state:RootState) => state,
    (state:RootState) => {
        const {config} = state.counter
        const {order} = state.order

        const windowsTime = order.windows.reduce((total: number, window:IOrderWindow) => {
            const current_win_config = config.windows.filter((conf_window:any) => conf_window.id === window.windowId)[0]
            total = total + (window.amount * current_win_config.washing_time);
            return total
        }, 0)

        // Add high window washing time
        const highWinTime = order.highGlassAmount > 0 ? config.high_window_base_time + (order.highGlassAmount * config.high_washing_time) : 0;
    
        const highOpeningWinTime =  order.highOpeningAmount > 0 ? config.high_window_opening_base_time + (order.highOpeningAmount * config.high_opening_washing_time) : 0;

        let balconyTime = (config.balcony_washing_time * order.balconyGlassAmount) 
        let railingTime = 0;

        if(order.balconyRailings && order.balconyGlassAmount > 0 ){
            railingTime = order.balconyRailingsObstacle ? 
                (config.balcony_railing_obstacle_washing_time * order.balconyGlassAmount ) :
                (config.balcony_railing_washing_time * order.balconyGlassAmount )

        } else if ( order.balconyRailingMeters > 0) {
            railingTime = order.balconyRailingsObstacle ? 
                (config.balcony_railing_obstacle_meter_washing_time * order.balconyRailingMeters ) : 
                (config.balcony_railing_meter_washing_time * order.balconyRailingMeters )
        }

        const terraceTime = (order.terraceGlassAmount * config.terrace_washing_time) + (order.terraceRoof ? config.terrace_roof_washing_time : 0)

        const sealTime = order.miscSealAmount > 0 ? config.misc_seal_base_time + (order.miscSealAmount * config.misc_seal_washing_time) : 0;
        const fastenerTime = order.miscFastenerAmount * config.misc_fastener_washing_time;
        const miscTime = sealTime + fastenerTime;
                            
        return config.base_time + windowsTime + highWinTime + highOpeningWinTime +  balconyTime + railingTime + terraceTime + miscTime;
    })

export const getOrderTotal = createSelector(
    discountTypeSelector,
    basePriceSelector,
    minimumPriceSelector,
    (state:RootState) => state,
    (discountType, base_price, minimum_price, state) => {
        // If counter config not yet loaded
        if(!state.counter.config || state.order?.order?.windows?.length === 0){
            return {total: 0, discountTotal: NaN};
        } 
        const {windowsTotal/*, windowDiscountTotal*/} = getWindowsTotal(state);
        const {balconyTotal/*, balconyDiscountTotal*/} = getBalconyTotal(state);
        const {terraceTotal/*, terraceDiscountTotal*/} = getTerraceTotal(state);
        const {miscTotal/*, miscDiscountTotal*/} = getMiscTotal(state);

        // const payment_type_discount = state.order.order.address.paymentType === PAYMENT_TYPE.ONLINE ? 7.9 : 0;
        
        const rawTotal: number = base_price + windowsTotal + balconyTotal + terraceTotal + miscTotal - VERKKOTILAAJAN_ETU;
        const totalPrice:number = rawTotal < minimum_price ? minimum_price : rawTotal;
        
        let discountTotalPrice:number = NaN;
        // let rawDiscountTotal:number = NaN;
        const discount_base_price = calculateDiscount(state,  {price: base_price, isFinalPrice: true});        


        if(!isNaN(discount_base_price)){
            discountTotalPrice = calculateDiscount(state, { price: totalPrice, isFinalPrice: true });
            discountTotalPrice = discountTotalPrice < 0 ? 0 : discountTotalPrice;

            // if (discountType === DISCOUNT_TYPE.FIXED) {
            //     discountTotalPrice = calculateDiscount(state, { price: totalPrice, isFinalPrice: true })
            // } else if (discountType === DISCOUNT_TYPE.PERCENT) {
            //     discountTotalPrice = discount_base_price + (windowDiscountTotal || 0) + (balconyDiscountTotal || 0) + (terraceDiscountTotal || 0) - VERKKOTILAAJAN_ETU;
                // discountTotalPrice = Math.floor(totalPrice - (totalPrice * state.discount.discount.discount / 100));
                // const discountedMinPrice = Math.floor(minimum_price - (minimum_price * state.discount.discount.discount / 100));
                // const discountWindows = (windowDiscountTotal || 0) + (balconyDiscountTotal || 0) + (terraceDiscountTotal || 0);
                // rawDiscountTotal = discount_base_price + discountWindows - VERKKOTILAAJAN_ETU
                // rawDiscountTotal = discount_base_price + discountWindows - Math.floor(VERKKOTILAAJAN_ETU - VERKKOTILAAJAN_ETU * state.discount.discount.discount / 100);
                // discountTotalPrice = rawDiscountTotal < discountedMinPrice ? discountedMinPrice : rawDiscountTotal;
            // }
        }
        
        return {
            total: totalPrice,
            rawTotal: rawTotal, // Raw order price regardless of minimum order price amount.
            discountTotal: discountTotalPrice,
            // rawDiscountTotal: rawDiscountTotal
        }; 
    }
)

export const getMallOrderTotal = createSelector(
    basePriceSelector,
    (state: RootState) => state,
    (base_price, state) => { // TODO add also base price to total. Currently base price is set to 0 in server (for malls). It is not configurable in admin.
        // If counter config not yet loaded
        if (!state.counter.config || state.order?.order?.windows?.length === 0) {
            return { mall_total: 0 };
        }

        const set_price = 49; // TODO to db and configurable from admin?
        const { amount, price} = state.order.order.windows[0]; // TODO create getMallWindowsTotal and not assume only one type of window.
        // const { windowsTotal } = getWindowsTotal(state);
        const totalPrice: number = amount < 11 ? set_price : set_price + (amount - 10) * price;       

        return {
            mall_total: !amount ? 0 : totalPrice
        };
    }
)