export const POST_CREATE_ORDER = "POST_CREATE_ORDER"
export const POST_CREATE_MALL_ORDER = "POST_CREATE_MALL_ORDER"
export const GET_ORDER = "GET_ORDER"
export const GET_ORDERS = "GET_ORDERS"
export const DELETE_ORDER = "DELETE_ORDER"
export const CHANGE_WINDOW_AMOUNT = "CHANGE_WINDOW_AMOUNT"
export const INIT_ORDER_WINDOWS = "INIT_ORDER_WINDOWS"
export const CHANGE_BALCONY_AMOUNT = "CHANGE_BALCONY_AMOUNT"
export const CHANGE_TERRACE_AMOUNT = "CHANGE_TERRACE_AMOUNT"
export const SET_SHIPPING_DAY = "SET_SHIPPING_DAY"
export const SET_ADDRESS_TO_ORDER = "SET_ADDRESS_TO_ORDER"
export const UPDATE_WINDOWS_PRICES = "UPDATE_WINDOWS_PRICES"
export const GET_PAYMENT_ORDER = "GET_PAYMENT_ORDER"
export const UPDATE_ADDITIONAL_INFO = "UPDATE_ADDITIONAL_INFO"
export const CHANGE_HIGH_WINDOWS = "CHANGE_HIGH_WINDOWS"
export const MALL_SELECTED = "MALL_SELECTED"
export const CHANGE_MALL_CLEANING_SET = "CHANGE_MALL_CLEANING_SET"
export const CHANGE_MALL_STARTING_MONTH = "CHANGE_MALL_STARTING_MONTH"
export const CHANGE_SEAL_AMOUNT = "CHANGE_SEAL_AMOUNT"
export const CHANGE_FASTENER_AMOUNT = "CHANGE_FASTENER_AMOUNT"
