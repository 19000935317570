import React, { useCallback, useEffect, useState } from 'react';
import { withFormik } from 'formik';
import AddressForm, { AddressSchema } from './AddressForm';
import { useDispatch, useSelector } from 'react-redux';
import { changeStartingMonthAction, IOrderWindow, postCreateMallOrder, postCreateOrder, setAddressToOrder, setShippingDay } from '../../actions/order';
import { RootState } from '../../reducers/rootReducer';
import { ADDRESS_TYPE, INVOICE_CHANNEL, MALL_CLEANING_SET, ORDER_ADDRESS_STATUS, /*InitialAddressValues,*/ PAYMENT_TYPE } from '../../reducers/order';
import moment from 'moment';
import { basePriceSelector, getMallOrderTotal, getOrderTotal, getWashingTime, isMallWindow, MAKSUTAPA_ALENNUS, minimumPriceSelector, REPEATED_ORDER_DISCOUNT, VERKKOTILAAJAN_ETU } from '../../utils/customSelectors';
import { useHistory } from 'react-router-dom';
import { DISCOUNT_CODE_STATES, DISCOUNT_TYPE } from '../../admin/discount/constants';
import ReactGA from 'react-ga4';
import { GA_CATEGORY, GA_ACTION } from '../../utils/analytics.constant';
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from 'react-i18next';


export enum BILLING_FEES {
    PAPER = 2.90,
    ONLINE = 2.90,
    EINVOICE = 2.90
}
const BILLING_FEE_EASOFT_ID = '700660';
const DISCOUNT_ROW_EASOFT_ID = '700651';
const PAYMENT_TYPE_DISCOUNT_EASOFT_ID = '700659';
const BASEPRICE_EASOFT_ID = '700450';
const REPEATED_ORDER_EASOFT_ID = '700652';
const MALL_CLEANER_ID = 9999;
const MALL_EASOFT_IDS = { yllapito: '900101', perus: '900102', loisto: '900103' };
// const MALL_EXTRA_WINDOW_EASOFT_ID = '900100'; // Is set in admin panel for näyteikkuna window.

export const getMallOrderDetails = (order: MALL_CLEANING_SET) => {
    return order === MALL_CLEANING_SET.MAINTAIN ?
        { name: 'Ylläpito', easoftId: MALL_EASOFT_IDS.yllapito } : order === MALL_CLEANING_SET.BASIC ?
            { name: 'Perus', easoftId: MALL_EASOFT_IDS.perus } : order === MALL_CLEANING_SET.SHINE ?
                { name: 'Loisto', easoftId: MALL_EASOFT_IDS.loisto } : { name: '', easoftId: '0' };
}

interface IOrderItem  {
    name: string,
    amount: number,
    price: number,
    easoft_item_id: string,
}

const Address = (props: { addressPageElement: any }) => {
    
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { order, isLoading, error, created } = useSelector((state:RootState) => state.order );
    const { config } = useSelector((state:RootState) => state.counter );
    const { discountCode, valid, discount } = useSelector((state:RootState) => state.discount)
    const { total, discountTotal, rawTotal } = useSelector((state:RootState) => getOrderTotal(state))
    const { mall_total } = useSelector((state:RootState) => getMallOrderTotal(state))
    const base_price = useSelector((state:RootState) => basePriceSelector(state))
    const minimum_price = useSelector((state:RootState) => minimumPriceSelector(state))
    const washing_time = useSelector((state:RootState) => getWashingTime(state))

    const [defaultAdditionalInfo, setDefaultAdditionalInfo ] = useState<string|boolean>(false) 
    useEffect(() => {
        if(!defaultAdditionalInfo)
            setDefaultAdditionalInfo(order.additionalInfo || '')
    }, [defaultAdditionalInfo, order.additionalInfo])
    
    useEffect(() => {
        if (!!order.mall && !order.shipping_day) {
            setStartingDate(order.mall.starting_month)
        }
    }, [])

    // customer payload
    const buildCustomer = (address: any)  => ({
        name: address.name,
        email: address.email,
        phone: address.phone
    })    

    // Address payload
    const buildAddresses = (address: any) => {
        
        let addressPayload:Array<object> = [];
        const mainAddress = {
            name: address.name,
            street_address: address.streetAddress,
            postal_code: address.postalCode,
            city: address.city,
            message: address.message,
            type: ADDRESS_TYPE.SHIPPING
        }
        addressPayload.push(mainAddress)

        if(address.billing){
            addressPayload.push({
                name: address.billingName,
                street_address: address.billingStreetAddress,
                postal_code: address.billingPostalCode,
                city: address.billingPostalCity,
                message: "", // NOT IN USE
                type: ADDRESS_TYPE.BILLING
            })
        } else {
            addressPayload.push({
                ...mainAddress, 
                type: ADDRESS_TYPE.BILLING
            })
        }
        return addressPayload;
    }

    const buildOrderPayload = useCallback(() => {
        const isMall = !!order.mall;
        const addBillingForMall = isMall && order.address.invoice_channel === INVOICE_CHANNEL.PAPER;

        // If order is less than minimum order, we adjust the base price higher to reach minimum price.
        const getModifiedBasePrice = () :number => {
            const total = rawTotal as number;
            const basePrice = base_price as number;
            const remainder = minimum_price - total;
            return (remainder > 0) ? (basePrice + remainder) : basePrice;
        }

        const buildOrderItems = (order: any): Array<IOrderItem> => {
            // Windows
            let orderItems:Array<IOrderItem> = order.windows.map((item:IOrderWindow) => {
                // Do not add mall windows if amount is 10 or less, since they are included in the set price.
                const isMallW = isMallWindow(item);
                if (isMallW && item.amount <= 10) {
                    // console.log('included in package, no windows added.');
                    return undefined;
                }
                if (item.amount > 0) {
                    return {
                        name: item.name,
                        amount: isMallW ? item.amount - 10 : item.amount, // 10 windows is included in price for malls.
                        price: item.price,
                        discount_price: item.discount_price,
                        easoft_item_id: item.easoft_item_id
                    }
                }
                return undefined;
            },[])
            .filter(( element:any ) => element !== undefined );

            // Base price or mall cleaning set
            if (isMall && order.mall.cleaning_set) {
                const set = getMallOrderDetails(order.mall.cleaning_set);
                orderItems.push({
                    name: `Pesupaketti ${set.name}`,
                    amount: 1,
                    price: 49,
                    easoft_item_id: set.easoftId
                })

            } else {
                orderItems.unshift({
                    name: t('order.base_price'),
                    amount: 1,
                    price: getModifiedBasePrice(),
                    easoft_item_id: BASEPRICE_EASOFT_ID //
                })
            }

            // High windows
            if(order.highGlassAmount > 0) {
                    
                // Add high base price if price > 0
                if(config.high_window_base_price > 0) {
                    orderItems.push({
                        name: t('order.base_price_high'),
                        amount: 1,
                        price: config.high_window_base_price,
                        easoft_item_id: config.high_window_base_easoft_id
                    })
                }

                orderItems.push({
                    name: t('order.high_windows'),
                    amount: order.highGlassAmount,
                    price: config.high_window_price,
                    easoft_item_id: config.high_window_easoft_item_id
                })
            }
            
            // High opening windows
            if(order.highOpeningAmount > 0) {

                if(config.high_window_opening_base_price > 0) {
                    orderItems.push({
                        name: t('order.base_price_high_opening'),
                        amount: 1,
                        price: config.high_window_opening_base_price,
                        easoft_item_id: config.high_window_opening_base_easoft_id
                    })
                }
                orderItems.push({
                    name: t('order.high_windows_opening'),
                    amount: order.highOpeningAmount,
                    price: config.high_window_opening_price,
                    easoft_item_id: config.high_window_opening_easoft_item_id
                })
            }


            // Balcony
            if(order.balconyGlassAmount > 0 ){
                orderItems.push({
                    name: t('order.balcony'),
                    amount: order.balconyGlassAmount,
                    price: config.balcony_glass_price,
                    easoft_item_id: config.balcony_easoft_item_id
                })
            }

            // Balcony railings
            if(order.balconyRailings || order.balconyRailingMeters > 0) {
                let br_amount:number = 0;
                let br_price:number = 0;
                let br_ea_item_id:string = config.balcony_railing_easoft_item_id;

                //  balcony glass 
                if(order.balconyGlassAmount > 0){
                    br_amount = order.balconyGlassAmount;
                    br_price = config.balcony_glass_railing_price;
                    br_ea_item_id = config.balcony_railing_easoft_item_id;
                    // if railing obstacle
                    if(order.balconyRailingsObstacle){
                        br_price = br_price + config.balcony_glass_railing_obstacle_price
                        br_ea_item_id = config.balcony_railing_obstacle_easoft_item_id;
                    }
                }

                // NO balcony glass
                else if(order.balconyRailingMeters > 0) {
                    br_amount = order.balconyRailingMeters;
                    br_price = config.balcony_glass_railing_meter_price;
                    br_ea_item_id = config.balcony_railing_meter_easoft_item_id;  
                    // if railing obstacle
                    if(order.balconyRailingsObstacle){
                        br_price = br_price + config.balcony_glass_railing_obstacle_meter_price
                        br_ea_item_id = config.balcony_railing_obstacle_meter_easoft_item_id;  
                    }
                }

                let railing_unit = order.balconyRailingMeters ? 'm, ' : ''; //t('summary_part.pcs'); Do not show units when its lasikaide related to kääntyvät lasit, even the price is calculated by amount of them.
                railing_unit = " (" + railing_unit + (t(order.balconyRailingsObstacle ? 'order.obstacles' : 'order.no_obstacles') ) + ")";

                orderItems.push({
                    name: t('order.balcony_rails') + ' ' + railing_unit,
                    amount: br_amount,
                    price: br_price,
                    easoft_item_id: br_ea_item_id
                })
            }
    
            // Terrace
            if(order.terraceGlassAmount > 0) {
                orderItems.push({
                    name: t('summary_part.terrace_glasses'),
                    amount: order.terraceGlassAmount,
                    price: config.terrace_glass_price,
                    easoft_item_id: config.terrace_easoft_item_id
                })
            }

            // Terassin lasilla aina kiinteä hinta 
            if(order.terraceRoof){
                orderItems.push({
                    name: t('summary_part.terrace_roof'),
                    //amount: order.terraceGlassAmount,
                    amount: 1,
                    price: config.terrace_glass_roof_fixed_price,
                    easoft_item_id: config.terrace_roof_easoft_item_id
                })
            }

            // Muut tuotteet
            if(order.miscSealAmount > 0) {
                if (config.misc_seal_base_price > 0) { // If base price is not set, do not add the product...
                    orderItems.push({
                        name: t('order.base_price_seals'),
                        amount: 1,
                        price: config.misc_seal_base_price,
                        easoft_item_id: config.misc_seal_base_easoft_item_id
                    })
                }
                orderItems.push({
                    name: t('summary_part.window_seal'),
                    amount: order.miscSealAmount,
                    price: config.misc_seal_price,
                    easoft_item_id: config.misc_seal_easoft_item_id
                })
            }
            if(order.miscFastenerAmount > 0){
                orderItems.push({
                    name: t('summary_part.blind_fastener'),
                    amount: order.miscFastenerAmount,
                    price: config.misc_fastener_price,
                    easoft_item_id: config.misc_fastener_easoft_item_id
                })
            }

            // Billing fee. Add only for mall order with paper invoice
            if (addBillingForMall /*|| (!isMall && order.address.paymentType === PAYMENT_TYPE.BILL)*/) {
                orderItems.push({
                    name: t('order.charging_fee'),
                    amount: 1,
                    price: order.address.invoice_channel === INVOICE_CHANNEL.PAPER ? BILLING_FEES.PAPER : BILLING_FEES.ONLINE,
                    easoft_item_id: BILLING_FEE_EASOFT_ID
                })
            }
            if (!isMall) { // Add discounts only for non-mall orders
                orderItems.push({
                    name: t('summary_part.discount_online'),
                    amount: 1,
                    price: -VERKKOTILAAJAN_ETU,
                    easoft_item_id: DISCOUNT_ROW_EASOFT_ID
                })
                if (order.address.paymentType === PAYMENT_TYPE.ONLINE) {
                    orderItems.push({
                        name: t('summary_part.discount_payment'),
                        amount: 1,
                        price: -MAKSUTAPA_ALENNUS,
                        easoft_item_id: PAYMENT_TYPE_DISCOUNT_EASOFT_ID
                    })
                }
                if (order.address.repeated_order) {
                    orderItems.push({
                        name: t('summary_part.repeated_order'),
                        amount: 1,
                        price: -REPEATED_ORDER_DISCOUNT,
                        easoft_item_id: REPEATED_ORDER_EASOFT_ID
                    })
                }
            }

            return orderItems;
        }
        

        let orderTotal: number = isMall ? mall_total : total //< minimum_price ? minimum_price : total;
        // const discountTotalToShow = discountTotal < minimum_price ? minimum_price : discountTotal;
        let orderDiscounTotal: number = isMall ? mall_total : isNaN(discountTotal) ? orderTotal : discountTotal;

        
        // Add billing fee to order totals
        if (addBillingForMall /*|| (!isMall && order.address.paymentType === PAYMENT_TYPE.BILL)*/){
            const billingFee = order.address.invoice_channel === INVOICE_CHANNEL.PAPER ? BILLING_FEES.PAPER : BILLING_FEES.ONLINE;
            orderTotal = orderTotal + billingFee;
            orderDiscounTotal = orderDiscounTotal + billingFee;
        }

        // Add discounts that are not already in ordertotal to order totals. Verkkotilaajan etu and discount code discounts are already in order total.
        if (!isMall) {
            if (order.address.paymentType === PAYMENT_TYPE.ONLINE) {
                orderTotal = orderTotal - MAKSUTAPA_ALENNUS;
                orderDiscounTotal = orderDiscounTotal - MAKSUTAPA_ALENNUS;
            }
            if (order.address.repeated_order) {
                orderTotal = orderTotal - REPEATED_ORDER_DISCOUNT;
                orderDiscounTotal = orderDiscounTotal - REPEATED_ORDER_DISCOUNT;
            }
        }

        const orderPayload: any = {
            shipping_day: moment(order.shippingDay).toISOString(true), //moment(order.shippingDay).add(8,'hours').toISOString(true),
            base_price: base_price, // This is the original base price from DB.
            discount_type: valid === DISCOUNT_CODE_STATES.VALID ? discount.type : DISCOUNT_TYPE.UNDEFINED,
            discount_amount: valid === DISCOUNT_CODE_STATES.VALID ? discount.discount : 0,
            discount_code: discountCode,
            total_price: orderTotal,
            total_price_after_discount: orderDiscounTotal,
            payment_type: order.address.paymentType,
            company_name: order.address.companyName,
            company_vat: order.address.companyVat,
            company_einvoice_address: order.address.company_einvoice_address,
            company_operator_id: order.address.company_operator_id,
            invoice_channel: order.address.invoice_channel,
            addresses: buildAddresses(order.address),
            customer: buildCustomer(order.address),
            order_items: buildOrderItems(order),
            additional_info: order.additionalInfo,
            cleanerId: isMall ? MALL_CLEANER_ID : order.cleanerId, // SHOPPING MALL EDITION - no cleaner id 
            washing_time: washing_time,
            marketing_permission: order.address.marketing_permission,
            repeated_order: order.address.repeated_order,
            international_cleaner: order.address.international_cleaner,
            clean_interval: Number(order.address.clean_interval),
            language: i18n.language === 'en' ? 'en' : 'fi'
        }
        if (isMall) {
            orderPayload.mall_name = order.mall.mall_name;
            orderPayload.mall_starting_month = order.mall.starting_month;
            orderPayload.mall_cleaning_set = order.mall.cleaning_set;
        }
        return orderPayload;
    },[order, base_price, valid, discount.type, discount.discount, discountCode, total, discountTotal, config, washing_time])

    useEffect(()=> {
        const {order_address_status: orderAddressStatus} = order.address
        if(orderAddressStatus === ORDER_ADDRESS_STATUS.SET && !error && !isLoading && !created){
            const payload = buildOrderPayload();
            // console.log('ORDER TO SEND: ', payload);
            if (payload.mall_name) {
                dispatch(postCreateMallOrder(payload));    
            } else {
                dispatch(postCreateOrder(payload));
            }
        }        

        if(created && !error && orderAddressStatus === ORDER_ADDRESS_STATUS.SET){
            // @ts-ignore
            if(created.payment_type === PAYMENT_TYPE.BILL ){
                ReactGA.event({
                    category: GA_CATEGORY,
                    action: GA_ACTION.ORDER,
                    label: 'BILL_PAYMENT',
                    value: created.total_price_after_discount / 100
                })
                ReactPixel.track('Purchase', {
                    currency: "EUR", 
                    value: created.total_price_after_discount
                });
                history.push('/kiitos-tilauksesta')
            }else {
                history.push('/payment/create/')
            }
        }

    },[history, order, error, isLoading, created, buildOrderPayload, dispatch])

    const removeCompanyData = (values: any) => {
        const resetCompanyData = {
            companyName: '',
            companyVat: '',
            company_operator_id: '',
            company_einvoice_address: '',
            eInvoiceAddress: '',
            operatorId: ''
        }
        return {...values, ...resetCompanyData}
    }

    const setStartingDate = (month: number) => {
        dispatch(changeStartingMonthAction(month));
        const today = moment(), currentMonth = today.month();
        let year = today.year();
        if (month < currentMonth) {
            year = year + 1; // Set next year if month is in the past.
        }
        dispatch(setShippingDay(moment([year, month]).toString())) // Set shipping day to the first day of the month.
    }

    const AddressEnhancedForm = withFormik({
        mapPropsToValues: () => ({ 
            ...order.address, 
            message: defaultAdditionalInfo.toString(), 
            isMall: !!order.mall, 
            mall_starting_month: order.mall?.starting_month,
            pageElement: props.addressPageElement
        }),
        validationSchema: AddressSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: false,
        handleSubmit: (values, { setSubmitting }) => {
            if (values.isMall) {
                setStartingDate(values.mall_starting_month);
            }
            // Remove company data if the customer is not Company but fields were filled.
            const valuesToDispatch = values.company ? values : removeCompanyData(values);           
            dispatch(setAddressToOrder(
                {
                    ...valuesToDispatch,
                    order_address_status: ORDER_ADDRESS_STATUS.SET
                }
                ));
            setSubmitting(false)
        },
        displayName: 'AddressForm'
    })(AddressForm);
  
    return (
        <div className="address-content p-grid">
            <AddressEnhancedForm />
        </div>
    );
};

export default Address
