
import * as Yup from "yup";


export const windowInitialValues = {
    id: '',
    title: '',
    title_eng: '',
    image: '',
    price: '',
    default_amount: 0,
    washing_time: 0
}

export const CCInitialValues = { 
    //name: '',
    base_price: '',
    minimum_price: '',
    annual_contract_discount_percent: '',
    windows: [windowInitialValues],
    balcony_glass_price: '',  
    balcony_glass_railing_price: '',
    balcony_glass_railing_obstacle_price: '',
    balcony_default_amount: 0,
    terrace_glass_price: '',
    terrace_glass_roof_price: '',
    terrace_default_amount: 0,
    high_window_base_price: '',
    high_window_price: '',
    high_window_opening_base_price: '',
    high_window_opening_price: '',
    high_window_default_amount: 0,
    washing_time: 0,
    high_opening_washing_time: 0,
    high_washing_time: 0,
    terrace_roof_washing_time: 0,
    terrace_washing_time: 0,
    balcony_washing_time: 0,
    balcony_railing_washing_time: 0,
    balcony_railing_obstacle_washing_time: 0,
    balcony_railing_meter_washing_time: 0,
    balcony_railing_obstacle_meter_washing_time: 0,
    balcony_railing_meter_easoft_item_id: '',
    balcony_railing_obstacle_meter_easoft_item_id: '',
    base_time: 0,
    high_window_base_time: 0,
    high_window_base_easoft_id: '',
    high_window_opening_base_time: 0,
    high_window_opening_base_easoft_item_id: '',
    misc_seal_base_time: 0,
    misc_seal_base_easoft_item_id: '',
    misc_seal_washing_time: 0,
    misc_seal_default_amount: 0,
    misc_seal_easoft_item_id: '',
    misc_fastener_washing_time: 0,
    misc_fastener_default_amount: 0,
    misc_fastener_easoft_item_id: '',
}

export const CCFormValidationSchema = Yup.object().shape({
    /*name: Yup.string()
        .min(3, 'Nimi on liian lyhyt')
        .max(56, 'Nimi saa sisältää enintään 56 merkkiä')
        .required("Nimi on pakollinen tieto"),*/
    base_price: Yup.number()
        .typeError('Pohjahinnan tulee olla numero')
        .required('Pakollinen tieto')
        .moreThan(0,'Oltava enemmän kuin 0e'),
    minimum_price: Yup.number()
        .typeError('Minimihinnan tulee olla numero')
        .required('Pakollinen tieto')
        .moreThan(0,'Oltava enemmän kuin 0e'),
    annual_contract_discount_percent: Yup.number()
        .typeError('Prosenttiluvun tulee olla numero')
        .required('Pakollinen tieto')
        .moreThan(0,'Oltava enemmän kuin 0%')
        .lessThan(100, 'Oltava alle 100%'),
    windows: Yup.array().of(
        Yup.object().shape({
            title: Yup.string()
                .required('Otsikko on pakollinen tieto'),
            title_eng: Yup.string()
                .required('Title is required'),
            price: Yup.number()
                .required('Hinta on pakollinen tieto')
                .typeError('Pohjahinnan tulee olla numero'),
            default_amount: Yup.number()
                .typeError('Valitun määrän tulee olla numero'),
            washing_time: Yup.number()
                .typeError('Valitun määrän tulee olla numero'),
            easoft_item_id: Yup.string()
                .required('Pakollinen tieto')
        })
    ),
    balcony_glass_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_glass_railing_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_glass_railing_obstacle_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_glass_railing_meter_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_glass_railing_obstacle_meter_price: Yup.string()
        .typeError('Arvon tulee olla numero')   
        .required('Pakollinen tieto'),
    balcony_default_amount: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    terrace_glass_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    terrace_glass_roof_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    terrace_glass_roof_fixed_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    terrace_default_amount: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    high_window_base_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    high_window_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    high_window_opening_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    high_window_default_amount: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    misc_seal_base_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    misc_seal_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    // misc_seal_default_amount: Yup.number()
    //     .typeError('Arvon tulee olla numero')
    //     .required('Pakollinen tieto'),
    misc_fastener_price: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    // misc_fastener_default_amount: Yup.number()
    //     .typeError('Arvon tulee olla numero')
    //     .required('Pakollinen tieto'),
        
    balcony_railing_meter_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    balcony_railing_obstacle_meter_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    balcony_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    balcony_railing_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    balcony_railing_obstacle_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    terrace_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    terrace_roof_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    high_window_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    high_window_opening_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    high_window_base_easoft_id: Yup.string()
    .required('Pakollinen tieto'),
    high_window_opening_base_easoft_id: Yup.string()
    .required('Pakollinen tieto'),
    misc_seal_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    misc_seal_base_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
    misc_fastener_easoft_item_id: Yup.string()
    .required('Pakollinen tieto'),
        
    // Washing times
    high_opening_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    high_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    terrace_roof_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    terrace_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_railing_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_railing_obstacle_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_railing_meter_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    balcony_railing_obstacle_meter_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    base_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    high_window_base_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    high_window_opening_base_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    misc_seal_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    misc_seal_base_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
    misc_fastener_washing_time: Yup.number()
        .typeError('Arvon tulee olla numero')
        .required('Pakollinen tieto'),
});

/*
        readonly washing_time: number;
        readonly high_opening_washing_time: number;
    readonly high_washing_time: number;
    readonly terrace_roof_washing_time: number;
    readonly terrace_washing_time: number;
    
    readonly balcony_washing_time: number;
    readonly balcony_railing_washing_time: number;
    readonly balcony_railing_obstacle_washing_time: number;
    readonly balcony_railing_meter_washing_time: number;
    readonly balcony_railing_obstacle_meter_washing_time: number;
    
    readonly base_time: number;
    */