import * as React from 'react'
import useDynamicRefs from 'use-dynamic-refs'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Title from '../components/Title';
import { FieldArray } from 'formik';
import { windowInitialValues } from './CounterConfigFormSchemas'
import { blobToBase64 } from '../../utils/image'
import placeholder from '../../static/img/placeholder-img.png'

const CounterConfigForm: React.FC<{}> = (props: any) => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isLoading,
      setFieldValue
    } = props;

    const [getRef, setRef] =  useDynamicRefs();

    const orderField = (type: string, field: string, label: string, fieldWidth: number = 3) =>  <div 
        className={`p-field p-col-12 p-md-${fieldWidth}`}>
        <label htmlFor={field} className="p-col-fixed label" >{label}</label>
        <div className="p-col-12">
            <InputText
                type={type}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[field]}
                name={field}
                id={field}
                className={'form-control' + (errors[field] && touched[field] ? ' p-error' : '' )} 
            />
            {errors[field] && touched[field] && <small id="" className="p-error">{errors[field]}</small>}
        </div> 
    </div>

    return (
      <form onSubmit={handleSubmit} >
        <Title type='h2'>Yleiset asetukset</Title>

        <div className="p-fluid p-formgrid p-grid cc-general-fields">
            {orderField("number", "base_time", "Pesun aloitusaika", 2)}
            {orderField("number", "base_price", "Pohjahinta", 2)}
            {orderField("number", "minimum_price", "Minimihinta", 2)}
            {orderField("number", "annual_contract_discount_percent", "Vuosisopimushinnan alennusprosentti", 2)}
        </div>

        <Title type='h2'>Ikkunat</Title>
        <FieldArray
            name="windows"
            render={arrayHelpers => (
            <>
                { (values.windows && values.windows.length > 0 ) ?
                    ( values.windows.map((window:any, i:number) => {
                        const ticketErrors = (errors.windows?.length && errors.windows[i]) ? errors.windows[i] : {};
                        const ticketTouched = (touched.windows?.length && touched.windows[i]) ? touched.windows[i] : {};
                        
                        return <div className="p-fluid p-formgrid p-grid window-row"  key={i}> 
                            <input type='hidden' value={window.id} name={`windows.${i}.id`}/>
                            <div className="p-field p-col-12 p-md-1 img-col">
                                <input
                                    className="hidden-img-input"
                                    type="file"
                                    name={`windows.${i}.image`}
                                    onChange={async (event:any) => {
                                        const base64img = await blobToBase64(event.target.files[0])
                                        setFieldValue(`windows[${i}].image`, base64img);
                                    }}
                                    // @ts-ignore
                                    ref={setRef(`windows.${i}.image`)}
                                />
                                <img 
                                // @ts-ignore
                                onClick={() => getRef(`windows.${i}.image`).current.click()} 
                                src={window.image !== '' ? window.image:  placeholder} 
                                className="thumbnail-img" 
                                alt="kuva"/>
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor={`windows.${i}.title`}>Otsikko</label>
                                <InputText 
                                    name={`windows.${i}.title`} 
                                    id={`windows.${i}.title`}
                                    value={window.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text" 
                                    className={'form-control' + (ticketErrors.title && ticketTouched.title ? ' p-error' : '' )} 
                                />
                                {ticketErrors.title && ticketTouched.title && <small id="" className="p-error">{ticketErrors.title}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor={`windows.${i}.title`}>Title(ENG)</label>
                                <InputText 
                                    name={`windows.${i}.title_eng`} 
                                    id={`windows.${i}.title_eng`}
                                    value={window.title_eng}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text" 
                                    className={'form-control' + (ticketErrors.title && ticketTouched.title ? ' p-error' : '' )} 
                                />
                                {ticketErrors.title_eng && ticketTouched.title_eng && <small id="" className='p-error'>{ticketErrors.title_eng}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-1">
                                <label htmlFor={`windows.${i}.price`}>hinta</label>
                                <InputText 
                                    name={`windows.${i}.price`} 
                                    id={`windows.${i}.price`}
                                    value={window.price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (ticketErrors.price && ticketTouched.price ? ' p-error' : '' )} 
                                />
                                {ticketErrors.price && ticketTouched.price && <small id="" className="p-error">{ticketErrors.price}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-1">
                                <label htmlFor={`windows.${i}.default_amount`}>Määrän esivalinta</label>
                                <InputText 
                                    name={`windows.${i}.default_amount`} 
                                    id={`windows.${i}.default_amount`}
                                    value={window.default_amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number" 
                                    className={'form-control' + (ticketErrors.default_amount && ticketTouched.default_amount ? ' p-error' : '' )} 
                                />
                                {ticketErrors.default_amount && ticketTouched.default_amount && <small id="" className="p-error">{ticketErrors.default_amount}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-1">
                                <label htmlFor={`windows.${i}.washing_time`}>Pesuaika (min)</label>
                                <InputText 
                                    name={`windows.${i}.washing_time`} 
                                    id={`windows.${i}.washing_time`}
                                    value={window.washing_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number" 
                                    className={'form-control' + (ticketErrors.washing_time && ticketTouched.washing_time ? ' p-error' : '' )} 
                                />
                                {ticketErrors.washing_time && ticketTouched.washing_time && <small id="" className="p-error">{ticketErrors.washing_time}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor={`windows.${i}.easoft_item_id`}>Easoft ID</label>
                                <InputText 
                                    name={`windows.${i}.easoft_item_id`} 
                                    id={`windows.${i}.easoft_item_id`}
                                    value={window.easoft_item_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number" 
                                    className={'form-control' + (ticketErrors.easoft_item_id && ticketTouched.easoft_item_id ? ' p-error' : '' )} 
                                />
                                {ticketErrors.easoft_item_id && ticketTouched.easoft_item_id && <small id="" className="p-error">{ticketErrors.easoft_item_id}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <Button className="remove-window-btn" type="button" onClick={() => arrayHelpers.remove(i)} >-</Button>
                            </div>
                        </div>
                        })
                    ) : ''
                }

            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-2">
                    <Button type="button" onClick={() => arrayHelpers.insert(values.windows.length, windowInitialValues)} >Lisää ikkuna</Button>
                </div>
            </div>

            <Title type='h2'>Parvekelasit (Kääntyvät lasit)</Title>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "balcony_glass_price", "Parvekelasien hinta", 2)}
                {orderField("number", "balcony_washing_time", "Parvekelasien pesuaika (min)", 2)}
                {orderField("number", "balcony_default_amount", "Määrän esivalinta", 2)}
                {orderField("text", "balcony_easoft_item_id", "Parvekelasien Easoft ID", 2)}

            </div>
            
            <Title type='h2'>Lasikaide esteetön (kpl)</Title>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "balcony_glass_railing_price", "Lasikaiteen hinta", 2)}
                {orderField("number", "balcony_railing_washing_time", "Lasikaiteen pesuaika (min)", 2)}
                {orderField("text", "balcony_railing_easoft_item_id", "Kaiteiden Easoft ID (kpl)", 2)}
            </div>

            <Title type='h2'>Lasikaide esteetön (m)</Title>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "balcony_glass_railing_meter_price", "Parvekekaiteen metrihinta", 2)}
                {orderField("number", "balcony_railing_meter_washing_time", "Lasikaidemetrin pesuaika (min)", 2)}
                {orderField("text", "balcony_railing_meter_easoft_item_id", "Kaidemetrin Easoft ID (m) NEW FIELD", 2)}
            </div>

            <Title type='h2'>Lasikaide esteellinen (kpl)</Title>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "balcony_glass_railing_obstacle_price", "Hinta esteellisenä", 2)}
                {orderField("number", "balcony_railing_obstacle_washing_time", "Lasikaiteen pesuaika (min) esteellisenä", 2)}
                {orderField("text", "balcony_railing_obstacle_easoft_item_id", "Esteellisen kaiteen Easoft ID", 2)}
            </div>

            <Title type='h2'>Lasikaide esteellinen (m)</Title>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "balcony_glass_railing_obstacle_meter_price", "Est. parvekekaiteen metrihinta", 2)}
                {orderField("number", "balcony_railing_obstacle_meter_washing_time", "Est. lasikaidemetrin pesuaika (min)", 2)}
                {orderField("text", "balcony_railing_obstacle_meter_easoft_item_id", "Est. lasikaidemetrin Easoft ID NEW FIELD", 2)}
            </div>


            <Title type='h2'>Terassi</Title>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "terrace_glass_price", "Terassilasien hinta", 2)}
                {orderField("number", "terrace_washing_time", "Terassilasien pesuaika (min)", 2)}
                {orderField("number", "terrace_default_amount", "Määrän esivalinta", 2)}
                {orderField("number", "terrace_glass_roof_fixed_price", "Lasikaton kiinteä hinta", 2)}
                {orderField("number", "terrace_roof_washing_time", "Lasikaton kiinteä pesuaika (min)", 2)}
                {orderField("number", "terrace_glass_roof_price", "Lasikaton hinta (Tämä ei käytössä)", 2)}
            </div>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("text", "terrace_easoft_item_id", "Terassilasien Easoft ID", 2)}
                {orderField("text", "terrace_roof_easoft_item_id", "Lasikaton Easoft ID", 2)}
            </div>

            <Title type='h2'>Kiinteä, yli 3m korkeudessa</Title>

            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "high_window_base_price", "Korkealla olevien pohjahinta", 2)}
                {orderField("number", "high_window_base_time", "Korkealla olevien aloitusaika", 2)}
                {orderField("text", "high_window_base_easoft_id", "Korkealla olevien pohjahinnan Easoft ID", 2)}
            
            </div>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "high_window_price", "Korkealla olevien hinta", 2)}
                {orderField("number", "high_washing_time", "Korkealla olevien pesuaika (min)", 2)}
                {orderField("number", "high_window_default_amount", "Määrän esivalinta", 2)}
                {orderField("text", "high_window_easoft_item_id", "Korkealla olevien Easoft ID", 2)}
            </div>
            <Title type='h2'>Avautuva, yli 3m korkeudessa</Title>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "high_window_opening_base_price", "Yli 3m ja aukeavien pohjahinta", 2)}
                {orderField("number", "high_window_opening_base_time", "Yli 3m ja aukeavien aloitusaika", 2)}
                {orderField("text", "high_window_opening_base_easoft_id", "Yli 3m ja aukeavien pohjahinnan easoft ID", 2)}
            
            </div>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "high_window_opening_price", "Yli 3m ja aukeava hinta", 2)}
                {orderField("number", "high_opening_washing_time", "Yli 3m ja aukeava pesuaika (min)", 2)}
                {orderField("text", "high_window_opening_easoft_item_id", "Yli 3m ja aukeavien Easoft ID", 2)}
            </div>
            <Title type='h2'>Muut</Title>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "misc_seal_base_price", "Tiivisteen pohjahinta", 2)}
                {orderField("number", "misc_seal_base_time", "Tiivisteen aloitusaika", 2)}
                {orderField("text", "misc_seal_base_easoft_item_id", "Tiivisteen pohjahinnan Easoft ID", 2)}
            </div>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "misc_seal_price", "Tiivisteen hinta", 2)}
                {orderField("number", "misc_seal_washing_time", "Tiivisteen vaihtoaika (min)", 2)}
                {/* {orderField("number", "misc_seal_default_amount", "Määrän esivalinta", 2)} */}
                {orderField("text", "misc_seal_easoft_item_id", "Tiivisteen Easoft ID", 2)}
            </div>
            <div className="p-fluid p-formgrid p-grid cc-general-fields">
                {orderField("number", "misc_fastener_price", "Kiinnikkeen hinta", 2)}
                {orderField("number", "misc_fastener_washing_time", "Kiinnikkeen vaihtoaika (min)", 2)}
                {/* {orderField("number", "misc_fastener_default_amount", "Määrän esivalinta", 2)} */}
                {orderField("text", "misc_fastener_easoft_item_id", "Kiinnikkeen Easoft ID", 2)}
            </div>
            </>
            )} />

        <Button disabled={isLoading} type="submit">Submit</Button>
       
      </form>
    );
  };

  export default CounterConfigForm;