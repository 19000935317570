/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import Windows from './Windows';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { RootState } from '../../reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getBalconyTotal, getMiscTotal, getTerraceTotal, getWindowsTotal } from '../../utils/customSelectors';
import Balcony from './Balcony';
import Terrace from './Terrace';
import AdditionalInfo from './AdditionalInfo';
import usePrevious from '../../hooks/usePrevious';
//import SlotEffect from '../../components/SlotEffect';
import ReactGA from 'react-ga4';
import { GA_CATEGORY, GA_ACTION } from '../../utils/analytics.constant';
import { setShowVideoGuide } from '../../actions/counter';
import { useTranslation } from 'react-i18next';
import Misc from './Misc';

interface TabTitleINF {
    title: string,
    blockTotal: number | string,
    discountBlockTotal: number | string
    prevBT: number,
    prevDiscountBT: number,
    guideStartingPoint?: number | null
}

const ACCORDIONS:Array<string> = [
    'Windows',
    'Balcony',
    'Terrace',
    'AdditionalInfo',
]

const WindowAccordion: React.FC<{}> = () => {

    const {windowsTotal, windowDiscountTotal} = useSelector((state:RootState) => getWindowsTotal(state))
    const {balconyTotal, balconyDiscountTotal}  = useSelector((state:RootState) => getBalconyTotal(state))
    const {terraceTotal, terraceDiscountTotal} = useSelector((state:RootState) => getTerraceTotal(state))
    const {miscTotal, miscDiscountTotal} = useSelector((state:RootState) => getMiscTotal(state))
    //const { highAccessibility } = useSelector((state: RootState) => state.order.order)

    // These are actually not used atm.
    const prevWindowsTotal = usePrevious(windowsTotal || 0);
    const prevwindowDiscountTotal = usePrevious(windowDiscountTotal || 0);
    const prevBalconyTotal = usePrevious(balconyTotal || 0);
    const prevBalconyDiscountTotal = usePrevious(balconyDiscountTotal || 0);
    const prevTerraceTotal = usePrevious(terraceTotal || 0);
    const prevTerraceDiscountTotal = usePrevious(terraceDiscountTotal || 0);
    const prevMiscTotal = usePrevious(miscTotal || 0);
    const prevMiscDiscountTotal = usePrevious(miscDiscountTotal || 0);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {width} = useWindowDimensions();
    // Set first accordion tab visible on desktop page load
    const [activeIndex, setActiveIndex] = useState(() => width > 992 ? 0: 0)

    const handleInfoClick = (e: any, startPoint: number) => {
        dispatch(setShowVideoGuide(startPoint));
        e.stopPropagation();
    }

    const getTabtitle = ({ title, blockTotal, discountBlockTotal, prevBT = 0, prevDiscountBT = 0, guideStartingPoint = 1 }: TabTitleINF): React.ReactNode => {
        //const discountClassName = isNaN(+discountBlockTotal) ? '' : 'discount-applied'

        return <div className="tab-tile">
            <div className="left">
                <span className="title">{title}</span>
            </div>
            {guideStartingPoint &&
                <div className="left right" onClick={(e) => handleInfoClick(e, guideStartingPoint)}>
                    <span className="title">
                        <i className="pi pi-play" ></i>
                        {' ' + t('guide')}
                    </span>
            </div>
            }
            {/*blockTotal > 0 && <>
                <span className={`block-price ${discountClassName}`}>{
                    highAccessibility !== false ? 
                    (isNaN(+discountBlockTotal) ? SlotEffect(prevBT, blockTotal) : (blockTotal + `€`) ) : 
                    '-' }</span>
                {!isNaN(+discountBlockTotal) && 
                    <span className="block-price discount-price">
                        {highAccessibility !== false ? SlotEffect(prevDiscountBT, discountBlockTotal): '-' }
                    </span>
                }
            </>*/}
        </div>
    }

    useEffect(() => {
        if(typeof(activeIndex) === 'number'){
            ReactGA.event({
                category: GA_CATEGORY,
                action: GA_ACTION.ACCORDION,
                label: ACCORDIONS[activeIndex]
              });
        }
    },[activeIndex])

    return (
        <div className="counter-windows p-col-12 p-lg-9 p-md-12 p-sm-12">
            <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)
                }>
                <AccordionTab header={getTabtitle({
                    title: t('tabs.windows_title'),
                    blockTotal: windowsTotal,
                    discountBlockTotal: windowDiscountTotal,
                    prevBT: prevWindowsTotal,
                    prevDiscountBT: prevwindowDiscountTotal
                })}>
                    <div className="content">
                        <Windows />
                    </div>
                </AccordionTab>

                <AccordionTab header={getTabtitle({
                    title: t('tabs.balcony_title'),
                    blockTotal: balconyTotal,
                    discountBlockTotal: balconyDiscountTotal,
                    prevBT: prevBalconyTotal,
                    prevDiscountBT: prevBalconyDiscountTotal,
                    guideStartingPoint: 173
                })}>
                    <div className="content">
                        <Balcony />
                    </div>
                </AccordionTab>
                
                <AccordionTab header={getTabtitle({
                    title: t('tabs.terrace_title'),
                    blockTotal: terraceTotal,
                    discountBlockTotal: terraceDiscountTotal,
                    prevBT: prevTerraceTotal,
                    prevDiscountBT: prevTerraceDiscountTotal,
                    guideStartingPoint: 193
                    })}>
                    <div className="content">
                        <Terrace />
                    </div>
                </AccordionTab>

                <AccordionTab header={getTabtitle({
                    title: t('tabs.other_title'),
                    blockTotal: miscTotal,
                    discountBlockTotal: miscDiscountTotal,
                    prevBT: prevMiscTotal,
                    prevDiscountBT: prevMiscDiscountTotal,
                    guideStartingPoint: null
                    })}>
                    <div className="content">
                        <Misc />
                    </div>
                </AccordionTab>

                <AccordionTab
                    headerClassName="additional-header"
                    header={getTabtitle({
                        title: t('additional_info'), 
                        blockTotal: 0,
                        discountBlockTotal: 0 ,
                        prevBT: 0,
                        prevDiscountBT: 0,
                        guideStartingPoint: null
                    })}>
                    <div className="content">
                        <AdditionalInfo />
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default WindowAccordion;

                   