
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { setShowVideoGuide } from '../../actions/counter';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { useTranslation } from 'react-i18next';

interface Props {
    playerWidth: number;
    playerHeight: number;
    startPoint?: number; // Starting point of the video, seconds from the start. If used from props configuration is needed to code!!
    videoId?: string; // Now hardcoded the guide video id. As props this component could be used also elsewhere.
    autoplay?: number;
}

/* VIDEOSTA
Ikkunat alkaa 30sec
Eri ikkunoilla mahd oma klikkaus mahis?
Parvekelasit 2min 53 sec = 173sec
terassit: 3min 13sec = 193sec
*/

const VideoGuide: React.FC<Props> = ({ playerWidth, playerHeight, startPoint, videoId, autoplay = 1 }: Props) => {

    const { show_video_guide } = useSelector((state: RootState) => state.counter);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const videoStartPoint = show_video_guide === 1 ? 0 : show_video_guide;
    // const muted = autoplay === 1 && width < 992 ? 1 : 0; // To get autoplay work with mobile devices. BUG: video restarts when resize over 992px, which is not nice and reason not to use this.


    const dialogWidth = playerWidth + 100; // 100 px is to match left+right padding of 50px


    const renderFooter = () => {
        return (
            <div>
                <Button label={t('close')} onClick={() => closeDialog()} autoFocus />
            </div>
        );
    }

    const closeDialog = () => {
        // setShowDialog(false)
        dispatch(setShowVideoGuide(0))
        // localStorage.setItem('showPostalCodeValidation', '0')
    }

    const getStyleObj = () => {
        return width < 992 ? {
            padding: 0,
            width: '100%',
            maxWidth: '100%'
        } :
        { 
            width: `${dialogWidth}px`, 
            maxWidth: `${dialogWidth}px` 
        }
    }


    return ( <div className="video-guide">
        <Dialog
            style={getStyleObj()}
            className='video-guide-dialog'
            // breakpoints={{ '992px': '100%' }} 
            header={width > 992 ? t('tabs.video_guide') : ''}
            visible={show_video_guide > 0}
            // showHeader={width > 992} // Hide header in mobile view.
            closeOnEscape={true}
            closable={width > 992} // As true (default), adds a close icon to the header to hide the dialog.
            footer={renderFooter()}
            onHide={() => closeDialog()}
        >
            <iframe
                id='ytplayer'
                width={width < 992 ? '100%' : playerWidth}
                height={playerHeight}
                src={`https://www.youtube.com/embed/puXS7syT1sc?autoplay=${autoplay}&modestbranding=1&iv_load_policy=3&start=${videoStartPoint}`}
                title={t('tabs.video_title')}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </Dialog>
    </div>
    );
};

export default VideoGuide;