
import { locale, addLocale } from 'primereact/api';
export const COUNTER_CONFIG_ID = 1;


export const SHOW_TIME_FORMAT = "DD.MM.YYYY" // HH:mm:ss
export const SHOW_TIME_FORMAT_H_M = "DD.MM.YYYY HH:mm" // :ss
export const SAVE_TIME_FORMAT = "DD-MM-YYYYTHH:mm:ssZ"

export const DISCOUNT_MIN_LENGTH = 4;

export const COMMON_TEL = "044 277 1279" // IF change, check if the whatsapp link from high windows needs to get changed.
export const COMMON_TEL2 = "044 277 1284" // IF change, check if the whatsapp link from high windows needs to get changed.

addLocale ('fi', {
    firstDayOfWeek: 1,
    dayNames: ["Sunnuntai", "Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai", "Lauantai"],
    dayNamesShort: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
    dayNamesMin: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
    monthNames: ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"],
    monthNamesShort: ["Tammi", "Helmi", "Maalis", "Huhti", "Touko", "Kesä", "Heinä", "Elo", "Syys", "Loka", "Marras", "Joulu"],
    today: 'Tänään',
    clear: 'Poista valinta',
    weekHeader: 'Vk'
});
addLocale ('en', {
    firstDayOfWeek: 1,
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: 'Today',
    clear: 'Clear selection',
    weekHeader: 'Week'
});
locale('fi');

Storage.prototype.setObj = function(key:any, obj:any) {
    return this.setItem(key, JSON.stringify(obj))
}
Storage.prototype.getObj = function(key:any) {
    //@ts-ignore
    return JSON.parse(this.getItem(key))
}

// Kotitalousvähennys ~ household deduction
const householdDeductionPercent = 0.35; // Updated: - 8/2022 from 0.4. - 12/2024 from 0.6.
// const deductible = 100;
const householdMaxDeduction = 1600; // Updated: - 8/2022 from 2250 - 12/2024 from 3500.
const materialCosts = 0; // Updated 9/2022 from 5

export const priceAfterHouseholdDeduction = (priceWithTax: number) => {
    const householdDeduction = Math.floor(
        (
            (priceWithTax <= householdMaxDeduction ? priceWithTax : householdMaxDeduction) 
            - materialCosts
        ) * householdDeductionPercent /*- deductible*/);

    return householdDeduction > 0 ? (priceWithTax - householdDeduction) : priceWithTax;
}

export const getPageUrl = (withSearch:boolean = true):string => {
    return (window.location.pathname + (withSearch ? window.location.search : '')).toString();
}
