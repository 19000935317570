import {
    LOAD_COUNTER_CONFIG,
    UPDATE_COUNTER_CONFIG,
    SET_BASE_PRICE_INFO_BLOCK,
    SET_SHOW_VIDEO_GUIDE
} from '../admin/counter/constants';

interface IcounterTranslate {
    name: string,
    created: string,
    modified: string
}

interface Iwindow {
    id: number,
    title: string,
    title_eng?: string,
    price: number,
    image: string,
    easoft_item_id: string,
    created: string,
    modified: string
}

interface IcounterConfig {
    config: {
        id: number,
        base_price: number,
        minimum_price: number,
        annual_contract_discount_percent: number,
        balcony_glass_price: number,
        balcony_easoft_item_id: string,
        balcony_default_amount: number,
        balcony_glass_railing_price: number,
        balcony_railing_easoft_item_id: string,
        balcony_glass_railing_meter_price: number,
        balcony_glass_railing_obstacle_meter_price: number,
        balcony_glass_railing_obstacle_price: number,
        balcony_railing_obstacle_easoft_item_id: number,
        terrace_glass_price: number,
        terrace_glass_roof_fixed_price: number,
        terrace_easoft_item_id: string,
        terrace_roof_easoft_item_id: string,
        terrace_glass_roof_price: number,
        terrace_default_amount: number,
        misc_seal_price: number,
        misc_seal_base_price: number,
        misc_seal_easoft_item_id: string,
        misc_seal_default_amount: number,
        misc_fastener_price: number,
        misc_fastener_easoft_item_id: string,
        misc_fastener_default_amount: number,
        high_window_base_price: number,
        high_window_price: number,
        high_window_easoft_item_id: string,
        high_window_opening_base_price: number,
        high_window_opening_price: number,
        high_window_opening_easoft_item_id: string,
        high_window_default_amount: number,
        high_window_base_time: number,
        high_window_base_easoft_id: string,
        high_window_opening_base_time: number,
        high_window_opening_base_easoft_id: string,

        created: string,
        modified:string,
        translate: IcounterTranslate,
        windows: [Iwindow]
    } | boolean,
    base_price_info_block: boolean,
    show_video_guide: number, // Seconds offset from start to start video at. 1 = from the beginning, 0 = hidden.
    isLoading: boolean,
    error: boolean,
    updated: boolean
}

const initialState: IcounterConfig = {
    config: false,
    base_price_info_block: false,
    show_video_guide: 0,
    isLoading: false,
    error: false,
    updated: false
}


export function CounterReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case `${LOAD_COUNTER_CONFIG}_PENDING`:
        case `${UPDATE_COUNTER_CONFIG}_PENDING`:
            return {
                ...state,
                ...{ error: false, isLoading: true, updated: false}
            }
        case `${LOAD_COUNTER_CONFIG}_FULFILLED`:
            return {
                ...state,
                ...{
                    config: action.payload,
                    error: false,
                    isLoading: false
                }
            }
        case `${UPDATE_COUNTER_CONFIG}_FULFILLED`:
            return {
                ...state,
                ...{
                    config: action.payload,
                    error: false,
                    isLoading: false,
                    updated: true
                }
            }
        case `${LOAD_COUNTER_CONFIG}_REJECTED`:
        case `${UPDATE_COUNTER_CONFIG}_REJECTED`:
            return {
                ...state,
                ...{ error: true, isLoading: false, updated: false }
            }
        case `${SET_BASE_PRICE_INFO_BLOCK}`:
            return {
                ...state,
                ...{ base_price_info_block: action.payload.seen }
            }
        case `${SET_SHOW_VIDEO_GUIDE}`:
            return {
                ...state,
                ...{ show_video_guide: action.payload }
            }
        default:
            return state
    }
}

