import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { Button } from 'primereact/button';
import misc_seal from '../../static/img/misc_seal.png'
import misc_fastener from '../../static/img/misc_fastener.png'
import { updateMiscFastenerAmount, updateMiscSealAmount } from '../../actions/order';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
//import icon_ready from '../../static/img/Icon_valmis.svg';

const Misc = () => {

    const { order, isLoading, error } = useSelector((state: RootState) => state.order);
    const { miscSealAmount, miscFastenerAmount } = order
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return <>

        {!isLoading &&
            <div className="ac-tab-content-wrapper">
                <div className="p-grid p-justify-even">
                    <div className="accordion-tab-col p-col">
                        <div className="title misc">{t('tabs.seal_amount')}</div>
                        <div className="image misc">
                            <img src={misc_seal} alt="Ikkunatiiviste" />
                        </div>
                        <div className="ac-tab-content-action-wrapper">
                            <Button
                                onClick={() => miscSealAmount > 0 && dispatch(updateMiscSealAmount({ miscSealAmount: miscSealAmount - 1}))}
                                className="minus-btn btn"
                            >
                                <i className="pi pi-minus" ></i>
                            </Button>
                            <input
                                className="amount"
                                // type="number" 
                                // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                                maxLength={4}
                                onClick={(event: any) => {
                                    event.target.setSelectionRange(0, event.target.value.length)
                                }}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const value = parseInt(e.target.value)
                                    dispatch(updateMiscSealAmount({ miscSealAmount: ((!isNaN(value) || value > 0) ? value : 0 )}))
                                }}
                                value={miscSealAmount}
                            />
                            <Button
                                onClick={() => dispatch(updateMiscSealAmount({ miscSealAmount: miscSealAmount + 1 }))}
                                className="plus-btn btn"
                            >
                                <i className="pi pi-plus" ></i>
                            </Button>
                        </div>
                    </div>
                    <div className="accordion-tab-col p-col">
                        <div className="title misc">{t('tabs.fastener_amount')}</div>
                        <div className="image misc">
                            <img src={misc_fastener} alt="Kiinnike" />
                        </div>
                        <div className="ac-tab-content-action-wrapper">
                            <Button
                                onClick={() => miscFastenerAmount > 0 && dispatch(updateMiscFastenerAmount({ miscFastenerAmount: miscFastenerAmount - 1}))}
                                className="minus-btn btn"
                            >
                                <i className="pi pi-minus" ></i>
                            </Button>
                            <input
                                className="amount"
                                // type="number" 
                                // type needs to be text(default) to let setSelection work. Will there be some downsides for this change??
                                maxLength={4}
                                onClick={(event: any) => {

                                    event.target.setSelectionRange(0, event.target.value.length)
                                }}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const value = parseInt(e.target.value)
                                    dispatch(updateMiscFastenerAmount({ miscFastenerAmount: ((!isNaN(value) || value > 0) ? value : 0 )}))
                                }}
                                value={miscFastenerAmount}
                            />
                            <Button
                                onClick={() => dispatch(updateMiscFastenerAmount({ miscFastenerAmount: miscFastenerAmount + 1 }))}
                                className="plus-btn btn"
                            >
                                <i className="pi pi-plus" ></i>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }
        {isLoading && <div className="loading-content-block">{t('loading')}</div>}
        {(!isLoading && error) && <div className="loading-content-block">{t('error')}</div>}

    </>
};

export default Misc
