import { IOrderWindow } from '../actions/order';
import { GET_VALIDATE_POSTALCODE } from '../admin/calendar/constants';
import {
    POST_CREATE_ORDER,
    GET_ORDER,
    CHANGE_WINDOW_AMOUNT,
    INIT_ORDER_WINDOWS,
    CHANGE_BALCONY_AMOUNT,
    CHANGE_TERRACE_AMOUNT,
    SET_SHIPPING_DAY,
    SET_ADDRESS_TO_ORDER,
    GET_ORDERS,
    DELETE_ORDER,
    UPDATE_WINDOWS_PRICES,
    GET_PAYMENT_ORDER,
    UPDATE_ADDITIONAL_INFO,
    CHANGE_HIGH_WINDOWS,
    CHANGE_MALL_CLEANING_SET,
    CHANGE_MALL_STARTING_MONTH,
    POST_CREATE_MALL_ORDER,
    MALL_SELECTED,
    CHANGE_SEAL_AMOUNT,
    CHANGE_FASTENER_AMOUNT
} from '../pages/order/constants';

export enum PAYMENT_TYPE {
    BILL = "BILL",
    ONLINE = "ONLINE"
}
export enum ADDRESS_TYPE {
    BILLING = "BILLING",
    SHIPPING = "SHIPPING"
}

export enum INVOICE_CHANNEL  {
    UNDEFINED="print",
    EMAIL="email",
    EINVOICE="evoice",
    PAPER="post"
}

export enum ORDER_ADDRESS_STATUS {
    INIT = 'INIT',
    SUBMITT='SUBMITT',
    SET='SET',
    ERROR='ERROR'
}

export enum MALL_CLEANING_SET {
    MAINTAIN = 'maintain',
    BASIC = 'basic',
    SHINE = 'shine'
}
export enum MALL_NAME {
    ITIS = 'itis',
    SELLO = 'sello',
    JUMBO = 'jumbo',
    ISO_OMENA = 'isoomena',
    MUU_OSTOSKESKUS = 'muuostoskeskus'
}
export interface MALL {
    name: MALL_NAME,
    address: string,
    postal_code: number | string
}

export interface MallOrder {
    mall_name: MALL_NAME | undefined,
    cleaning_set: MALL_CLEANING_SET,
    starting_month: number // 0 = January, 11=December
}



export interface Iorder {
    windows: Array<IOrderWindow>,
    balconyGlassAmount: number,
    balconyRailings: boolean,
    balconyRailingMeters: number,
    balconyRailingsObstacle: boolean | undefined,
    terraceGlassAmount: number,
    terraceRoof: boolean,
    miscSealAmount: number, // Ikkunan tiiviste
    miscFastenerAmount: number, // Sälekaihtimen säätimen kiinnike/narun kiinnitin
    shippingDay: string | undefined,
    cleanerId: number | boolean,
    address: IOrderAddress, 
    additionalInfo: string,
    highGlassAmount: number,
    highOpeningAmount: number,
    mall: MallOrder | undefined
}


export interface ICustomer {
    name: string,
    phone: string,
    email: string,
    easoft_id: number | string
}

export interface IOrderAddress {
    name: string,
    phone: string,
    email: string,
    streetAddress: string,
    postalCode: boolean|string,
    city: string,
    message: string,
    terms: boolean,
    company: boolean,
    companyName: string,
    companyVat: string,
    company_einvoice_address: string,
    company_operator_id: string,
    eInvoiceAddress: string,
    invoice_channel: INVOICE_CHANNEL | undefined,
    operatorId: string,
    billing: boolean,
    billingName: string
    billingStreetAddress: string,
    billingPostalCode: string,
    billingPostalCity: string,
    paymentType: PAYMENT_TYPE,
    marketing_permission: boolean,
    repeated_order: boolean,
    international_cleaner: boolean,
    clean_interval: number,
    order_address_status: ORDER_ADDRESS_STATUS
}

interface IinitialState {
    order: Iorder 
    orders: Array<any>,
    isLoading: boolean,
    error: boolean,
    deleted: boolean,
    created: any
}

export const InitialAddressValues:IOrderAddress = {
            name: '',
            phone: '',
            email: '',
            streetAddress: '',
            postalCode: '',
            city: '',
            message: '',
            terms: false,
            company: false,
            companyName: '',
            companyVat: '',
            company_operator_id: '',
            company_einvoice_address: '',
            eInvoiceAddress: '',
            operatorId: '',
            billing: false,
            billingName: '',
            billingStreetAddress: '',
            billingPostalCode: '',
            billingPostalCity: '',
            paymentType: PAYMENT_TYPE.ONLINE,
            invoice_channel: undefined, //INVOICE_CHANNEL.UNDEFINED,
            repeated_order: false,
            clean_interval: 12,
            international_cleaner: false,
            marketing_permission: false,
            order_address_status: ORDER_ADDRESS_STATUS.INIT
        }

export const initialOrder:Iorder = {
        windows: [],
        balconyGlassAmount: 0,
        balconyRailings: false,
        balconyRailingMeters: 0,
        balconyRailingsObstacle: undefined,
        terraceGlassAmount: 0,
        terraceRoof: false,
        miscSealAmount: 0,
        miscFastenerAmount: 0,
        shippingDay: new Date().toString(),
        cleanerId: false,
        address: InitialAddressValues,
        additionalInfo: '',
        highGlassAmount: 0,
        highOpeningAmount: 0,
        mall: undefined
    }


export const initialState:IinitialState = {
    order: initialOrder,
    orders: [],
    isLoading: false,
    deleted: false,
    error: false,
    created: false
}


export function OrderReducer(state = initialState, action: {type: string, payload: any}) {

    switch (action.type) {
        case `${POST_CREATE_ORDER}_PENDING`:
        case `${POST_CREATE_MALL_ORDER}_PENDING`:
        case `${GET_ORDER}_PENDING`:
        case `${DELETE_ORDER}_PENDING`:
        case `${GET_PAYMENT_ORDER}_PENDING`:
        case `${GET_VALIDATE_POSTALCODE}_PENDING`:  
            return {
                ...state,
                error: false,
                isLoading: true,
            }
        case `${POST_CREATE_MALL_ORDER}_REJECTED`:
        case `${POST_CREATE_ORDER}_REJECTED`:
            return {
                ...state,
                error: true,
                isLoading: false,
                created: false
            }
        case `${GET_ORDER}_REJECTED`:
        case `${GET_ORDERS}_REJECTED`:
        case `${DELETE_ORDER}_REJECTED`:
        case `${GET_PAYMENT_ORDER}_REJECTED`:
            return {
                ...state,
                error: true,
                isLoading: false,
            }
        case `${DELETE_ORDER}_FULFILLED`:
            return {
                ...state,
                orders: state.orders.filter((order: any, i) => order.id !== action.payload.id ),
                error: false,
                isLoading: false,
            }

        // Postal code validation
        case `${GET_VALIDATE_POSTALCODE}_FULFILLED`:
            return {
                ...state,
                order: {...state.order, address: { ...state.order.address, postalCode: action.payload.validCode }},
                error: false,
                isLoading: false,
            }

        case `${GET_VALIDATE_POSTALCODE}_REJECTED`:  
            return {
                ...state,
                order: {...state.order, address: { ...state.order.address, postalCode: false }},
                error: true,
                isLoading: false,
                created: false
            }

        case `${GET_ORDERS}_FULFILLED`:
            return {
                ...state,
                orders: action.payload,
                error: false,
                isLoading: false
            }
        case `${SET_ADDRESS_TO_ORDER}`:
            return {
                ...state,
                order: {
                    ...state.order,
                    ...action.payload
                },
                error: false,
                isLoading: false
            }
        // POST_CREATE_ORDER
        case `${POST_CREATE_MALL_ORDER}_FULFILLED`:
        case `${POST_CREATE_ORDER}_FULFILLED`:
            return {
                ...state,
                error: false,
                isLoading: false,
                created: action.payload
            }
        case `${GET_ORDER}_FULFILLED`:
            return {
                ...state,
                order: action.payload,
                error: false,
                isLoading: false
            }
        case `${GET_PAYMENT_ORDER}_FULFILLED`:
            return {
                ...state,
                created: action.payload,
                error: false,
                isLoading: false
            }
        case `${INIT_ORDER_WINDOWS}`:
            return {
                ...state,
                order: action.payload,
                created: false,
                error: false,
                isLoading: false
            }
        case `${CHANGE_WINDOW_AMOUNT}`:
            // eslint-disable-next-line no-case-declarations
            const newWindowAmounts:IOrderWindow[] = state.order.windows.map((window: IOrderWindow) => 
                    window.windowId === action.payload.id ? 
                        {...window, amount:action.payload.newAmount} : 
                        window
                );
            return {
                ...state,
                order: {
                    ...state.order,
                    ...{windows: newWindowAmounts}
                },
                error: false,
                isLoading: false
            }

        case `${MALL_SELECTED}`:            
            return {
                ...state,
                order: {
                    ...state.order,
                    address: {
                        ...state.order.address,
                        postalCode: action.payload.postal_code,
                        streetAddress: action.payload.address
                    },
                    mall: {
                        ...state.order.mall,
                        mall_name: action.payload.name
                    }
                }
            }
        case `${CHANGE_MALL_CLEANING_SET}`:            
            return {
                ...state,
                order: {
                    ...state.order, 
                    mall: {
                        ...state.order.mall,
                        cleaning_set: action.payload
                    }
                }
            }
        case `${CHANGE_MALL_STARTING_MONTH}`:            
            return {
                ...state,
                order: {
                    ...state.order,
                    mall: {
                        ...state.order.mall,
                        starting_month: action.payload
                    }
                }
            }
        case `${UPDATE_WINDOWS_PRICES}`:
        case `${CHANGE_TERRACE_AMOUNT}`:
        case `${CHANGE_BALCONY_AMOUNT}`:
        case `${SET_SHIPPING_DAY}`:
        case `${UPDATE_ADDITIONAL_INFO}`:
        case `${CHANGE_HIGH_WINDOWS}`:
        case `${CHANGE_SEAL_AMOUNT}`:
        case `${CHANGE_FASTENER_AMOUNT}`:
            return {
                ...state,
                order: {
                    ...state.order,
                    ...action.payload
                },
                error: false,
                isLoading: false
            }
        default:
            return state
    }
}

